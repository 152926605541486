import React, { useState, useEffect } from 'react';
import './App.css';
import './Global/shared-css.css';
import { Container, Spinner } from 'react-bootstrap';
import { initializeApp } from "firebase/app";
import { getFirestore, getDoc, doc } from "firebase/firestore";
import Err404 from './pages/404';
import { set } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyBp9usWMJXhylmeAGhkdvWUZKhshNqYMfM",
  authDomain: "usefnug.firebaseapp.com",
  projectId: "usefnug",
  storageBucket: "usefnug.appspot.com",
  messagingSenderId: "402679382664",
  appId: "1:402679382664:web:346623cfaac2fff013953d",
  measurementId: "G-D3Z8GQHSNT"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

var windowLocationPath = '';

if (window.location.pathname.includes('?fbclid') || window.location.pathname.includes('?utm') || window.location.pathname.includes('?ref') || window.location.pathname.includes('/?s')) {
  windowLocationPath = window.location.pathname.split('?')[0];
  console.log('windowLocationPath', windowLocationPath);
} else {
  windowLocationPath = window.location.pathname;
  console.log('windowLocationPath', windowLocationPath);
}

console.log('windowLocationPath', windowLocationPath);

const CheckData = () => {
  var currentdisplayurl = windowLocationPath;
  var trimcdl = currentdisplayurl.substring(1);
  const [isActive, setIsActive] = useState('');
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [issuedDate, setIssuedDate] = useState('');
  const [dob, setDob] = useState('');
  const [certID, setCertID] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true; // add a flag to track whether component is mounted

    const fetchData = async () => {
      try {
        setLoading(true);
        const documentRef = doc(db, 'cert', trimcdl);
        const documentSnapshot = await getDoc(documentRef);

        if (isMounted) { // only proceed if the component is still mounted
          if (documentSnapshot.exists()) {
            const data = documentSnapshot.data();
            setIsActive(data.active);
            setName(data.name);
            setSubject(data.subject);
            setDob(data.dob);
            setIssuedDate(data.timestamp);
            setCertID(data.uuid);
            console.log('cert', 'found with ' + trimcdl);
          } else {
            console.log('cert', 'not found with ' + trimcdl);
            setIsActive(false); // Set isActive to false if document doesn't exist
          }
        }
      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false; // clean up on unmount
    };
  }, [trimcdl]);

  return { isActive, name, subject, issuedDate, loading, certID, trimcdl, dob };

};

function App() {
  const { isActive, name, subject, issuedDate, loading, certID, trimcdl, dob } = CheckData();
  const currentYear = new Date().getFullYear();
  return (
    trimcdl === '' ?
      // <Err404 />
      <div className="App" style={{ background: "#F8F8FF" }}>
        <Container fluid className='uppercontainer'>
          <img src='https://cdn.egov.nugmyanmar.org/assets/ufes/ufes-logo.png' alt='UFES' className='logo' />
          <h2>Certificate Verification Portal</h2>
          <p className='beware'>Beware of phishing! Make sure your link starts with <b>verify.ufes.nugmyanmar.org</b></p>
          {/* <img src='https://cdn.egov.nugmyanmar.org/assets/ufes/unsuccess-mark.png' alt='Success' className='searchbarimg' /> */}
        </Container>
        <Container fluid className='lowercontainer'>
          {/* <p className='bewaremm text-secondary lh-lg'>ယခု အသိအမှတ်ပြုလက်မှတ်သည် မှန်ကန်မှုမရှိပါ။</p> */}
          <p className='redirectmm text-secondary'>
            <div className='redirectmm text-secondary'>Powered by
              <img
                src='https://cdn.egov.nugmyanmar.org/assets/state/smartgov-logo-3x%20(1280%20x%20600%20px).png'
                width={50}
                style={{ filter: 'grayscale(100%)' }}
                alt='Logo'
              />
            </div>
            © {currentYear} UFES Myanmar
          </p>
        </Container>
      </div>
      :
      <div className="App">
        <Container fluid className='m-0 p-0'>
          {loading ? (
            <div className="App" style={{ background: "#F8F8FF" }}>
              <Container fluid className='uppercontainer'>
                <img src='https://cdn.egov.nugmyanmar.org/assets/ufes/ufes-logo.png' alt='UFES' className='logo' />
                <h2>Certificate Verification Portal</h2>
                <p className='beware'>Beware of phishing! Make sure your link starts with <b>verify.ufes.nugmyanmar.org</b></p>
                <div className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              </Container>
              <Container fluid className='lowercontainer'>
                <p className='redirectmm text-secondary'>
                  <div className='redirectmm text-secondary'>Powered by
                    <img
                      src='https://cdn.egov.nugmyanmar.org/assets/state/smartgov-logo-3x%20(1280%20x%20600%20px).png'
                      width={50}
                      style={{ filter: 'grayscale(100%)' }}
                      alt='Logo'
                    />
                  </div>
                  © {currentYear} UFES Myanmar
                </p>
              </Container>
            </div>
          ) : (
            <>
              {isActive ? (
                <>
                  <div className="App" style={{ background: "rgb(229 252 231)" }}>
                    <Container fluid className='uppercontainer'>
                      <img src='https://cdn.egov.nugmyanmar.org/assets/ufes/ufes-logo.png' alt='UFES' className='logo' />
                      <h2>Certificate Verified</h2>
                      <p className='beware'>Beware of phishing! Make sure your link starts with <b>verify.ufes.nugmyanmar.org</b></p>
                      <img src='https://cdn.egov.nugmyanmar.org/assets/ufes/success-mark.png' alt='Success' className='searchbarimg' />

                    </Container>
                    <Container fluid className='lowercontainer'>
                        <p className='bewaremm text-secondary lh-lg'>The University of Forestry and Environmental Science, Yezin confers on <b>{name}</b> born on <b>{dob}</b> the degree of Bachelor of Science in <b>{subject}</b>, as evidenced by Certificate No. <b>{certID}</b> issued on <b>{issuedDate}</b>.</p>
                      <p className='redirectmm text-secondary'>
                        <div className='redirectmm text-secondary'>Powered by
                          <img
                            src='https://cdn.egov.nugmyanmar.org/assets/state/smartgov-logo-3x%20(1280%20x%20600%20px).png'
                            width={50}
                            style={{ filter: 'grayscale(100%)' }}
                            alt='Logo'
                          />
                        </div>
                        © {currentYear} UFES Myanmar
                      </p>
                    </Container>
                  </div>
                </>
              ) : (
                <div className="App" style={{ background: "rgb(252 229 229)" }}>
                  <Container fluid className='uppercontainer'>
                    <img src='https://cdn.egov.nugmyanmar.org/assets/ufes/ufes-logo.png' alt='UFES' className='logo' />
                    <h2>Certificate Unverified</h2>
                    <p className='beware'>Beware of phishing! Make sure your link starts with <b>verify.ufes.nugmyanmar.org</b></p>
                    <img src='https://cdn.egov.nugmyanmar.org/assets/ufes/unsuccess-mark.png' alt='Success' className='searchbarimg' />
                  </Container>
                  <Container fluid className='lowercontainer'>
                    <p className='bewaremm text-secondary lh-lg'>This certificate is void it is expired or is inactive.</p>
                    <p className='redirectmm text-secondary'>
                      <div className='redirectmm text-secondary'>Powered by
                        <img
                          src='https://cdn.egov.nugmyanmar.org/assets/state/smartgov-logo-3x%20(1280%20x%20600%20px).png'
                          width={50}
                          style={{ filter: 'grayscale(100%)' }}
                          alt='Logo'
                        />
                      </div>
                      © {currentYear} UFES Myanmar
                    </p>
                  </Container>
                </div>
              )}
            </>
          )}
        </Container>
      </div>
  );

}

export default App;