import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

const Err404 = () => {
    return (
        <Container className="mt-5">
            <Row>
                <Col>
                    <h1>404 - Page Not Found</h1>
                    <p>The page you are looking for does not exist.</p>
                    <Button variant="primary" href="/">Go Back to Home</Button>
                </Col>
            </Row>
        </Container>
    );
};

export default Err404;